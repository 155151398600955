import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { blockToComponent } from 'providers/blocks/blocks';

const PageBlocks = ({
    pageLocation,
    dataGALocation,
    modalBlock = false
}: {
    pageLocation: string;
    dataGALocation: string;
    modalBlock?: boolean;
}) => {
    const data = useStaticQuery(PageBlockQuery);
    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation.replace(/\/$/, '') === node.path?.alias?.toString()
    );

    let block = modalBlock
        ? applicationPageParagraphs[0]?.relationships?.field_modal_block
        : applicationPageParagraphs[0]?.relationships?.field_block;

    /**
     * DRX-1596 - FE: Updates to Sign In failed modal
     *
     * We are forcing the value of the field_block_highlight because we were not able
     * to implement this field inside Drupal CMS and bring it to the front-end using
     * GraphiQL. It looks like that block_content__cta_redirect have some kind of
     * cache or hardcode that does not allow to implement new fields. For now we will
     * force the value of this field but we will add a tech debit to fix this in the future.
     *
     * @TODO: remove this conditional and implement the correct field inside Drupal CMS.
     */
    if (block?.internal?.type === 'block_content__cta_redirect' && modalBlock) {
        block = {
            ...block,
            body: { ...block?.body, processed: '<p>Or</p>' },
            field_block_highlight: { processed: block?.body?.processed }
        };
    }

    if (block) {
        return blockToComponent(block.internal.type, block, dataGALocation, 10);
    } else {
        return <></>;
    }
};

export default PageBlocks;

export const PageBlockQuery = graphql`
    {
        allNodeApplicationPage {
            nodes {
                path {
                    alias
                }
                relationships {
                    field_block {
                        internal {
                            type
                        }
                        body {
                            processed
                        }
                        field_block_label
                        field_cta_link {
                            title
                            uri
                        }
                        field_link_style
                    }
                    field_modal_block {
                        internal {
                            type
                        }
                        body {
                            processed
                        }
                        field_block_label
                        field_cta_link {
                            title
                            uri
                        }
                        field_link_style
                    }
                }
            }
        }
    }
`;
